.interactive-map-holder .mapboxgl-popup-tip {
    border-top-color: #122a47 !important;
}

.interactive-map-holder .mapboxgl-popup-content {
    background-color: #122a47 !important;
    border-radius: 8px !important;
}

.interactive-map-holder .mapboxgl-popup {
    max-width: none !important;
}
