.pagination-results .p-dropdown {
    align-items: center;
    padding: 1em;
}

.pagination-results .p-inputtext {
    padding: 0;
}

.pagination-results .p-dropdown-trigger {
    justify-content: flex-end;
}
