.panel-position {
    top: 52px !important;
    left: 0 !important;
}

.search-panel-holder .p-autocomplete-token-label {
    line-height: 2 !important;
}

.search-panel-holder .p-autocomplete-token {
    padding: 0 1em !important;
}

.search-panel .p-autocomplete-panel {
    overflow: scroll;
    width: 10%;
    overflow: hidden;

}
