.p-tabview-nav-link,
.p-tabview .p-tabview-nav {
    background-color: #F8F8F8 !important;
    border: none !important;
    box-shadow: none !important;
}

.p-tabview-selected,
.p-tabview-selected a {
    background: linear-gradient(89.37deg, rgba(18, 42, 71, 0.972136) -0.74%, #225B7B 85.93%) !important;
    border-radius: 50px !important;
    color: white !important;
}

.p-tabview-ink-bar {
    display: none !important;
}

.interactive-map-tab-selector .p-tabview .p-tabview-panels {
    display: none !important;
}
