.link {
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0;
    cursor: pointer;
    line-height: 1;
    color: #545355;
    width: max-content;
    transition: all 0.2s ease-out;
}

.link:hover {
    color: #000000;
}

.link::after {
    display: inline-block;
    margin: 0 5px;
    vertical-align: middle;
    width: 30px;
    height: 60px;
    border-right: solid 30px rgb(200, 30, 50);
    border-bottom: solid 30px transparent;
    border-top: solid 30px transparent;
}

.link-active {
    position: relative;
    font-weight: 700;
    color: black;
    padding-left: 1.5em;
}

.link-active::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 1em;
    height: 1em;
    background-image: url(assets/images/icon-red-arrow.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}