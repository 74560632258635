@media (min-width: 1500px) {
    .custom-cols-4 {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 25%;
    }
}

.custom-filter .p-float-label input~label,
.custom-filter .p-float-label .p-inputwrapper-focus~label {
    color: #f75151;
    opacity: 1;
}

.custom-filter .p-float-label input {
    color: #f75151;
    opacity: 1;
}
