.eula-content {
    padding-top: 3em;
    padding-bottom: 3em;
}

.eula-content .h1,
.eula-content h1 {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 15pt;
}

.eula-content> :first-child {
    display: none;
}

.eula-content .p,
.eula-content p {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
    margin: 0pt;
}

.eula-content .s1 {
    color: #00F;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    font-size: 12pt;
}

.eula-content h2 {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 12pt;
}

.eula-content li {
    display: block;
}

.eula-content #l1 {
    padding-left: 0pt;
    counter-reset: c1 1;
}

.eula-content #l1>li>*:first-child:before {
    counter-increment: c1;
    content: counter(c1, decimal)". ";
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 15pt;
}

.eula-content #l1>li:first-child>*:first-child:before {
    counter-increment: c1 0;
}

.eula-content #l2 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

.eula-content #l2>li>*:first-child:before {
    counter-increment: c2;
    content: counter(c2, decimal)". ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
}

.eula-content #l2>li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

.eula-content #l3 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

.eula-content #l3>li>*:first-child:before {
    counter-increment: c2;
    content: counter(c2, decimal)". ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
}

.eula-content #l3>li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

.eula-content #l4 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

.eula-content #l4>li>*:first-child:before {
    counter-increment: c2;
    content: counter(c2, decimal)". ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
}

.eula-content #l4>li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

.eula-content #l5 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

.eula-content #l5>li>*:first-child:before {
    counter-increment: c2;
    content: counter(c2, decimal)". ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
}

.eula-content #l5>li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

.eula-content #l6 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

.eula-content #l6>li>*:first-child:before {
    counter-increment: c2;
    content: counter(c2, decimal)". ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
}

.eula-content #l6>li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

.eula-content #l7 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

.eula-content #l7>li>*:first-child:before {
    counter-increment: c2;
    content: counter(c2, decimal)". ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
}

.eula-content #l7>li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

.eula-content #l8 {
    padding-left: 0pt;
    counter-reset: c3 2;
}

.eula-content #l8>li>*:first-child:before {
    counter-increment: c3;
    content: "("counter(c3, lower-latin)") ";
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

.eula-content #l8>li:first-child>*:first-child:before {
    counter-increment: c3 0;
}

.eula-content #l9 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

.eula-content #l9>li>*:first-child:before {
    counter-increment: c2;
    content: counter(c2, decimal)". ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
}

.eula-content #l9>li:first-child>*:first-child:before {
    counter-increment: c2 0;
}


/* footer */
.eula-footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1em;
    padding: 0 0 10em 0;
}
