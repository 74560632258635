  .profile-gallery-vessel-swiper {
      width: 100%;
      height: 100%;
  }

  .profile-gallery-vessel-swiper .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
  }
